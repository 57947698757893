import { inject, Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  runTransaction,
  writeBatch
} from '@angular/fire/firestore';
import { UserQuery } from '../user/user.query';
import {
  FirebaseUserWithConnectedRescueOrgs,
  OrganizationUser,
  RescueOrgState,
  RescueOrgStore
} from './rescue-org.store';
import { FormBuilder } from '@angular/forms';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError } from 'rxjs';
import {rescueOrgCollectionKey, userCollectionKey} from "../../globalVariables";

@Injectable({ providedIn: 'root' })
export class RescueOrgService {
  private firestore: Firestore = inject(Firestore);
  private userQuery = inject(UserQuery);
  private rescueOrgStore = inject(RescueOrgStore);
  private fb = inject(FormBuilder);

  private readonly rescueOrgRef = collection(this.firestore, rescueOrgCollectionKey);
  private readonly userRef = collection(this.firestore, userCollectionKey);

  rescueOrgDefaults = this.fb.group({
    name: [{ value: '', disabled: true }],
    email: [''],
    website: [''],
    phone: [''],
    zip: [''],
    city: [''],
    address: [''],
    door: [''],
  });

  rescueOrgBank = this.fb.group({
    bank: [''],
    bankNumber: [''],
    tax: [''],
  })

  removeUserFromOrg(user: OrganizationUser) {
    return fromPromise(
      runTransaction(this.firestore, async (transaction) => {
        if (user.role) {
          const userDocRef = doc(this.userRef, user.id);
          const rescueOrgUserRef = doc(
            this.firestore,
            'rescueOrg',
            this.userQuery.selectedRescueOrgId,
            user.role,
            user.id
          );

          const userDoc = await transaction.get(userDocRef);
          const userData = (userDoc.data() as FirebaseUserWithConnectedRescueOrgs);

          const updatedOrgs = userData.connectedOrgs;
          delete updatedOrgs[this.userQuery.selectedRescueOrgId];

          transaction.delete(rescueOrgUserRef);
          transaction.update(userDocRef, {
            connectedOrgs: updatedOrgs
          });
        }
      })
    ).pipe(catchError((err) => {
      throw new Error(err);
    }))
  }

  getRescueOrgInfo() {
    this.userQuery.selectSelectedRescueOrgId$.subscribe((orgId) => {
      if (!orgId.trim()) return;
      const rescueOrgDoc = getDoc(doc(this.firestore, rescueOrgCollectionKey, orgId));
      rescueOrgDoc.then((orgData) => {
        this.rescueOrgStore.reset();
        this.rescueOrgStore.update(orgData.data() as RescueOrgState);
      }).catch((err) => {
        console.log(err);
      })
    })
  }

  updateRescueOrgInfo(documentId: string) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const rescueOrgDocRef = doc(this.rescueOrgRef, documentId);
      const batch = writeBatch(this.firestore);

      if (!rescueOrgDocRef) reject('Rescue organization document does not exists!');

      if (this.rescueOrgDefaults.dirty) {
        const changedValuesOfDefaults = this.rescueOrgDefaults.value;

        if (Object.values(changedValuesOfDefaults).some((value) => typeof value === 'undefined'))
          reject('One or more of the values are undefined which is rejected by Firebase!')

        batch.set(rescueOrgDocRef, changedValuesOfDefaults, { merge: true });
      }

      await batch.commit();

      this.rescueOrgStore.update(this.rescueOrgDefaults.value as RescueOrgState);
      resolve(this.rescueOrgDefaults.get('name')?.value);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }

  updateRescueOrgBankInfo(documentId: string) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const rescueOrgDocRef = doc(this.rescueOrgRef, documentId);
      const batch = writeBatch(this.firestore);

      if (!rescueOrgDocRef) reject('Rescue organization document does not exists!');

      if (this.rescueOrgBank.dirty) {
        const changedValuesOfBank = this.rescueOrgBank.value;

        if (Object.values(changedValuesOfBank).some((value) => typeof value === 'undefined'))
          reject('One or more of the values are undefined which is rejected by Firebase!')

        batch.set(rescueOrgDocRef, changedValuesOfBank, { merge: true });
      }

      await batch.commit();

      this.rescueOrgStore.update(this.rescueOrgBank.value as RescueOrgState);
      resolve(this.rescueOrgDefaults.get('name')?.value);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }
}
